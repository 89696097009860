<script setup lang="ts">
import VszLogo from "/images/logos/vsz-logo.svg";

const props = defineProps(["href"]);
</script>

<template>
  <div>
    <NuxtLink
      v-if="!props!.href"
      href="/#home"
      class="d-flex justify-start align-start"
      style="cursor: pointer;"
      v-smooth-scroll="{
        duration: 300,
        offset: -87,
      }"
    >
      <v-img height="50" width="90" contain :src="VszLogo" alt="logo" />
    </NuxtLink>
  </div>
</template>
